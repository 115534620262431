<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Servicios Internos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Servicios</li>
                  <li class="breadcrumb-item active">Internos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-1">
                    <label>N° Servicio</label>
                    <input
                      type="number"
                      v-model="filtros.servicio_id"
                      placeholder="N°Servicio"
                      label="id"
                      class="form-control form-control-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-1">
                    <label>N° Solicitud</label>
                    <input
                      type="number"
                      v-model="filtros.solicitud_id"
                      placeholder="N° Solicitud"
                      label="id"
                      class="form-control form-control-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo servicio </label>
                    <select
                      id="tipo_servicio"
                      class="form-control form-control-sm"
                      v-model="filtros.tipo_servicio"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_servicio in listasForms.tipos_servicios"
                        :key="tipo_servicio.numeracion"
                        :value="tipo_servicio.numeracion"
                      >
                        {{ tipo_servicio.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Placa </label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.placa"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Conductor/Operador </label>
                    <select
                      id="persona_type"
                      v-model="filtros.persona_type"
                      class="form-control form-control-sm p-0"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option value="App\Conductor">Conductor</option>
                      <option value="App\Operario">Operario</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Nombre Conductor</label>
                    <input
                      type="text"
                      id="nombre"
                      class="form-control form-control-sm"
                      v-model="filtros.nombre"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Empresa </label>
                    <v-select
                      v-model="empresa"
                      label="razon_social"
                      class="form-control form-control-sm p-0"
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      :options="empresas"
                      :filterable="false"
                      @search="buscarEmpresas"
                      @input="getIndex()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Bloque </label>
                    <v-select
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="bloque"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.bloques"
                      @input="seleccionarBloque()"
                      class="form-control form-control-sm p-0"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Sitio </label>
                    <v-select
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="sitio"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="seleccionarSitio()"
                      class="form-control form-control-sm p-0"
                    ></v-select>
                  </div>

                  <div class="form-group col-md-2">
                    <label>Fecha inicial </label>
                    <input
                      type="date"
                      id="fecha_ini"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_ini"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Final </label>
                    <input
                      type="date"
                      id="fecha_fin"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_fin"
                    />
                  </div>
                  <div class="col-md-2">
                    <label>Estado </label>
                    <select
                      id="estado"
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2 float-right">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn bg-navy mt-3"
                        @click="getIndex()"
                      >
                        <i class="fas fa-search"></i><br />Buscar
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger mt-3"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i><br />Limpiar
                      </button>
                      <button
                        type="button"
                        class="btn btn-success mt-3"
                        data-toggle="modal"
                        data-target="#modal_export_servicios_internos"
                        @click="$refs.CsServicioExport.limpiarExport()"
                        v-if="$store.getters.can('cs.serviciosInterno.export')"
                      >
                        <i class="far fa-file-excel"></i><br />
                        Excel
                      </button>
                      <button
                        style="color: white;"
                        type="button"
                        class="btn bg-frontera btn-sm mt-3"
                        @click="redireccionamientoSolicitud()"
                      >
                        <i class="far fa-eye"> Solicitudes Internas</i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table class="table table-bordered table-sm">
                  <tbody>
                    <template
                      v-for="(grupo, indicegroup) in serviciosInternos.grupos"
                    >
                      <tr
                        data-widget="expandable-table"
                        aria-expanded="false"
                        class="bg-light"
                        :key="indicegroup"
                      >
                        <td style="font-size: 10px">
                          <div class="row">
                            <div class="col-sm-1 m-0">
                              <div class="d-flex w-100 justify-content-between">
                                <h3 class="header-des text-bold text-sm">
                                  # Solicitud:
                                </h3>
                                {{
                                  grupo.det_solicitud_interna != null
                                    ? grupo.det_solicitud_interna
                                        .solicitud_interna.id
                                    : ""
                                }}
                              </div>
                            </div>
                            <div class="col-sm-2 m-0">
                              <div class="d-flex w-100 justify-content-between">
                                <h3 class="header-des text-bold text-sm">
                                  Tipo Servicio:
                                </h3>
                                {{
                                  grupo.servicio != null
                                    ? grupo.servicio.nTipoServicio
                                    : grupo.det_solicitud_interna != null
                                    ? grupo.det_solicitud_interna.nTipoServicio
                                    : ""
                                }}
                              </div>
                            </div>
                            <div class="col-sm-1 m-0">
                              <div class="d-flex w-100 justify-content-between">
                                <h3 class="header-des text-bold text-sm">
                                  # Vh / Eq:
                                </h3>
                                {{ grupo.equipo_indice }}
                              </div>
                            </div>
                            <div class="col-sm-3 m-0">
                              <div class="d-flex w-100 justify-content-between">
                                <h3 class="header-des text-bold text-sm">
                                  Tipo Vehículo / Equipo:
                                </h3>
                                <span
                                  v-if="
                                    grupo.det_solicitud_interna.tipo_servicio ==
                                      1
                                  "
                                >
                                  {{
                                    grupo.det_solicitud_interna.tipo_vh_equi ==
                                    1
                                      ? grupo.det_solicitud_interna.nTipoVh +
                                        " - " +
                                        grupo.det_solicitud_interna
                                          .nTipoRemolque
                                      : grupo.det_solicitud_interna.nTipoVh
                                  }}
                                </span>
                                <span
                                  v-if="
                                    grupo.det_solicitud_interna.tipo_servicio ==
                                      2
                                  "
                                >
                                  {{ grupo.det_solicitud_interna.nTipoEqui }}
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-1 m-0">
                              <div class="d-flex w-100 justify-content-between">
                                <h3 class="header-des text-bold text-sm">
                                  Sitio:
                                </h3>
                                <span v-if="grupo.servicio != null">
                                  {{
                                    grupo.servicio.sitio != null &&
                                    grupo.servicio.sitio != undefined
                                      ? grupo.servicio.sitio.nombre
                                      : ""
                                  }}
                                </span>
                                <span v-else>
                                  <div
                                    v-if="grupo.det_solicitud_interna != null"
                                  >
                                    {{
                                      grupo.det_solicitud_interna
                                        .solicitud_interna.sitio !== null &&
                                      grupo.det_solicitud_interna
                                        .solicitud_interna.sitio !== undefined
                                        ? grupo.det_solicitud_interna
                                            .solicitud_interna.sitio.nombre
                                        : ""
                                    }}
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-4 m-0">
                              <div class="d-flex w-100 justify-content-between">
                                <h3 class="header-des text-bold text-sm">
                                  Empresa:
                                </h3>
                                {{
                                  grupo.servicio != null
                                    ? grupo.servicio.empresa.razon_social
                                    : ""
                                }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        class="expandable-body d-none"
                        :key="indicegroup + 'a'"
                      >
                        <td>
                          <div class="card-body p-0">
                            <table
                              class="table table-bordered table-sm m-2"
                              style="font-size: 0.85em"
                            >
                              <thead class="bg-frontera text-white text-center">
                                <tr>
                                  <th class="text-center"># Servicio</th>

                                  <th class="text-center">Tipo Servicio</th>
                                  <th class="text-center">Día</th>

                                  <th class="text-center">Placa</th>
                                  <th class="text-center">
                                    Conductor/Operador
                                  </th>

                                  <th class="text-center">Fecha Inicial</th>
                                  <th class="text-center">Fecha Final</th>
                                  <th class="text-center">Bloque</th>
                                  <th class="text-center">Sitio</th>
                                  <th class="text-center">Estado</th>
                                  <th class="text-center">
                                    Acciones
                                    <button
                                      type="button"
                                      class="btn btn-sm bg-info pt-0 pb-0 pl-1 pr-1"
                                      @click="createServices()"
                                      v-if="
                                        checkPost.filter(
                                          (item) => item === true
                                        ).length > 0
                                      "
                                    >
                                      <i class="fas fa-check sm"></i>
                                    </button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template
                                  v-for="(serv,
                                  index) in serviciosInternos.data"
                                >
                                  <tr
                                    :class="[
                                      serv.servicio == null
                                        ? 'bg-lightdanger'
                                        : serv.servicio.estado == 1
                                        ? 'bg-lightwarning'
                                        : 'bg-lightsuccess',
                                    ]"
                                    :key="index"
                                    v-if="shouldShowCell(grupo, serv)"
                                  >
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-default py-0"
                                        data-toggle="modal"
                                        data-target="#modal-form-detalles"
                                        @click="
                                          $refs.CsConsultaDetalle.getIndex(
                                            serv.servicio
                                          )
                                        "
                                        v-if="serv.servicio != null"
                                      >
                                        {{ serv.servicio.id }}
                                      </button>
                                    </td>

                                    <td>
                                      {{
                                        serv.servicio != null
                                          ? serv.servicio.nTipoServicio
                                          : serv.det_solicitud_interna != null
                                          ? serv.det_solicitud_interna
                                              .nTipoServicio
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{ serv.pos_dia }}
                                    </td>

                                    <td>
                                      {{
                                        serv.equipo !== null &&
                                        serv.equipo !== undefined
                                          ? serv.equipo.placa
                                          : ""
                                      }}
                                    </td>

                                    <td>
                                      <div
                                        v-if="
                                          serv.persona !== null &&
                                            serv.persona !== undefined
                                        "
                                      >
                                        {{
                                          serv.persona_type == "App\\Conductor"
                                            ? "Conductor: "
                                            : "Operario: "
                                        }}
                                        {{ serv.persona.nombres }}
                                        {{ serv.persona.apellidos }}
                                        <br />
                                        <span
                                          class="badge badge-info float-left"
                                        >
                                          {{ serv.persona.numero_documento }}
                                        </span>
                                      </div>
                                    </td>

                                    <td>
                                      {{
                                        serv.servicio != null
                                          ? serv.servicio.fecha_ini
                                          : ""
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        serv.servicio != null
                                          ? serv.servicio.fecha_fin
                                          : ""
                                      }}
                                    </td>
                                    <td v-if="serv.servicio != null">
                                      {{
                                        serv.servicio.bloque != null &&
                                        serv.servicio.bloque != undefined
                                          ? serv.servicio.bloque.nombre
                                          : ""
                                      }}
                                    </td>
                                    <td v-else>
                                      <div
                                        v-if="
                                          serv.det_solicitud_interna != null
                                        "
                                      >
                                        {{
                                          serv.det_solicitud_interna
                                            .solicitud_interna.bloque !==
                                            null &&
                                          serv.det_solicitud_interna
                                            .solicitud_interna.bloque !==
                                            undefined
                                            ? serv.det_solicitud_interna
                                                .solicitud_interna.bloque.nombre
                                            : ""
                                        }}
                                      </div>
                                    </td>
                                    <td v-if="serv.servicio != null">
                                      {{
                                        serv.servicio.sitio !== null &&
                                        serv.servicio.sitio !== undefined
                                          ? serv.servicio.sitio.nombre
                                          : ""
                                      }}
                                    </td>
                                    <td v-else>
                                      <div
                                        v-if="
                                          serv.det_solicitud_interna != null
                                        "
                                      >
                                        {{
                                          serv.det_solicitud_interna
                                            .solicitud_interna.sitio !== null &&
                                          serv.det_solicitud_interna
                                            .solicitud_interna.sitio !==
                                            undefined
                                            ? serv.det_solicitud_interna
                                                .solicitud_interna.sitio.nombre
                                            : ""
                                        }}
                                      </div>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="serv.servicio != null"
                                    >
                                      <span
                                        class="badge"
                                        :class="
                                          serv.servicio.estado == 1
                                            ? 'badge-secondary'
                                            : serv.servicio.estado == 2
                                            ? 'badge-warning'
                                            : serv.servicio.estado == 3
                                            ? 'bg-navy'
                                            : serv.servicio.estado == 4
                                            ? 'bg-success'
                                            : serv.servicio.estado == 5
                                            ? 'badge-info'
                                            : serv.servicio.estado == 6
                                            ? 'badge-success'
                                            : 'bg-light'
                                        "
                                      >
                                        {{ serv.servicio.nEstado }}
                                      </span>
                                      <br />
                                      <span
                                        class="badge bg-light"
                                        v-if="
                                          serv.servicio.firmas.length > 0 &&
                                            serv.servicio.estado != 4
                                        "
                                      >
                                        <b style="font-size: 10px; color: gray"
                                          >Firmado Por:</b
                                        >
                                        <br />
                                        <span
                                          v-for="firma in serv.servicio.firmas"
                                          :key="firma.id"
                                        >
                                          {{ firma.nTipoFirma }} <br />
                                        </span>
                                      </span>
                                    </td>
                                    <td class="text-center" v-else>
                                      <span
                                        class="badge"
                                        :class="
                                          serv.estado == 1
                                            ? 'badge-primary'
                                            : serv.estado == 3
                                            ? 'bg-danger'
                                            : 'bg-light'
                                        "
                                      >
                                        {{ serv.nEstado }}
                                      </span>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="serv.servicio != null"
                                    >
                                      <div class="btn-group">
                                        <button
                                          type="button"
                                          class="btn btn-sm bg-navy"
                                          @click="verDetalle(serv)"
                                          v-if="
                                            $store.getters.can(
                                              'cs.serviciosInterno.create'
                                            )
                                          "
                                        >
                                          <i class="fas fa-eye"></i>
                                        </button>
                                        <button
                                          type="button"
                                          class="btn bg-teal btn-sm"
                                          data-toggle="modal"
                                          data-target="#modal-form-firmas"
                                          @click="
                                            llenarModalFirmas(
                                              serv.servicio,
                                              serv
                                            )
                                          "
                                          v-if="
                                            ($store.getters.can(
                                              'cs.serviciosInterno.firmaOperador'
                                            ) ||
                                              $store.getters.can(
                                                'cs.serviciosInterno.firmaAparejador'
                                              ) ||
                                              $store.getters.can(
                                                'cs.serviciosInterno.firmaSupervisor'
                                              ) ||
                                              $store.getters.can(
                                                'cs.serviciosInterno.firmaConductor'
                                              ) ||
                                              $store.getters.can(
                                                'cs.serviciosInterno.firmaSolicitante'
                                              ) ||
                                              $store.getters.can(
                                                'cs.serviciosInterno.firmaCampo'
                                              )) &&
                                              serv.servicio.estado == 3
                                          "
                                        >
                                          <i class="fas fa-signature"></i>
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-sm"
                                          :class="
                                            serv.servicio.detalle_servicios
                                              .length == 0 ||
                                            serv.servicio.presupuestos.length ==
                                              0 ||
                                            serv.servicio.totalPresupuesto < 100
                                              ? 'bg-danger'
                                              : 'bg-success'
                                          "
                                          @click="
                                            finalizarServicio(serv.servicio)
                                          "
                                          v-if="
                                            $store.getters.can(
                                              'cs.serviciosInterno.finalizar'
                                            ) &&
                                              (serv.servicio.estado == 1 ||
                                                serv.servicio.estado == 2)
                                          "
                                        >
                                          <i class="fas fa-flag-checkered"></i>
                                        </button>
                                      </div>
                                    </td>
                                    <td class="text-center" v-else>
                                      <input
                                        v-if="
                                          $store.getters.can(
                                            'cs.serviciosInterno.crearServicio'
                                          )
                                        "
                                        type="checkbox"
                                        v-model="checkPost[index]"
                                        @change="
                                          llenarServices(
                                            serv.id,
                                            checkPost[index]
                                          )
                                        "
                                      />
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <!-- <div class="card-footer">
                <div class="float-left" v-if="serviciosInternos.total">
                  Mostrando del <b>{{ serviciosInternos.from }}</b> al
                  <b>{{ serviciosInternos.to }}</b> de un total:
                  <b>{{ serviciosInternos.total }}</b> Registros
                </div>
                <div class="float-left" v-else>
                  <span class="badge badge-danger">
                    No hay registros para mostrar
                  </span>
                </div>
                <pagination
                  :data="serviciosInternos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right m-0"
                ></pagination>
              </div> -->
            </div>
          </div>
        </section>
        <CsServicioExport ref="CsServicioExport" />
        <CsConsultaDetalle ref="CsConsultaDetalle" />
      </div>
    </div>
    <CsServicioFirmas
      ref="CsServicioFirmas"
      v-if="
        $store.getters.can('cs.serviciosInterno.firmaOperador') ||
          $store.getters.can('cs.serviciosInterno.firmaAparejador') ||
          $store.getters.can('cs.serviciosInterno.firmaSupervisor') ||
          $store.getters.can('cs.serviciosInterno.firmaConductor') ||
          $store.getters.can('cs.serviciosInterno.firmaSolicitante') ||
          $store.getters.can('cs.serviciosInterno.firmaCampo')
      "
    ></CsServicioFirmas>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import CsServicioFirmas from "./CsServicioFirmas";
import CsServicioExport from "./CsServicioExport";
import CsConsultaDetalle from "./CsConsultaDetalle";

export default {
  name: "CsServicioIndex",

  components: {
    Loading,
    vSelect,
    CsServicioFirmas,
    CsServicioExport,
    CsConsultaDetalle,
  },

  data() {
    return {
      cargando: false,
      sitio: null,
      empresa: null,
      bloque: null,
      /* ruta: null, */
      serviciosInternos: {},
      firmantes: {},
      listasForms: {
        tipos_servicios: [],
        estados: [],
      },
      checkPost: [],
      filtros: {
        servicio_id: null,
      },
      formPost: [],
      estados_modulo: [1, 2, 3],
      empresas: [],
    };
  },

  computed: {
    filteredRowsDetail() {
      if (this.serviciosInternos.data) {
        return this.serviciosInternos.data.filter((res) => {
          /* Resultado de res */
          const idServ = res.id.toString().toLowerCase();
          const vhEqui = res.equipo.placa.toLowerCase();
          const condApa = res.persona.numero_documento.toLowerCase();
          const fechaIni = res.fecha_ini.toLowerCase();
          const fechaFin = res.fecha_fin.toLowerCase();

          /* Constantes modelos de los inputs */
          const searchTermIdServ = this.buscarResum.servicio.toLowerCase();
          const searchTermvh = this.buscarResum.vhEqu.toLowerCase();
          const searchTermcondApa = this.buscarResum.condApa.toLowerCase();
          const searchFechaIni = this.buscarResum.fechaIni.toLowerCase();
          const searchFechaFin = this.buscarResum.fechaFin.toLowerCase();
          return (
            vhEqui.includes(searchTermvh) &&
            condApa.includes(searchTermcondApa) &&
            fechaIni.includes(searchFechaIni) &&
            fechaFin.includes(searchFechaFin) &&
            idServ.includes(searchTermIdServ)
          );
        });
      } else {
        return this.serviciosInternos.data;
      }
    },
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.filtros.empresa_id = this.empresa ? this.empresa.id : null;
      let me = this;
      axios
        .get("/api/cs/servicios?page=" + page, {
          params: this.filtros,
        })
        .then(async (response) => {
          this.serviciosInternos = response.data;
          /*  for (let k = 0; k < this.serviciosInternos.data.length; k++) {
            let totalPresupuesto = 0;
            if (
              this.serviciosInternos.data[k].servicio.presupuestos.length > 0 &&
              this.serviciosInternos.data[k].servicio.presupuestos != undefined
            ) {
              this.serviciosInternos.data[k].servicio.presupuestos.forEach(
                (presupuesto) => {
                  totalPresupuesto += parseFloat(presupuesto.porcentaje);
                }
              );
            }
            this.serviciosInternos.data[
              k
            ].servicio.totalPresupuesto = totalPresupuesto;
          } */
          this.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    shouldShowCell(grupo, det) {
      return (
        grupo.cs_det_solicitud_interna == det.cs_det_solicitud_interna &&
        grupo.equipo_indice == det.equipo_indice
      );
    },

    getTipoServicio() {
      axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    getEstados() {
      axios
        .get("/api/lista/143", {
          params: { in_number: this.estados_modulo },
        })
        .then((response) => {
          this.listasForms.estados = response.data;
        });
    },

    getFirmantes() {
      axios.get("/api/lista/171").then((response) => {
        this.firmantes = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    seleccionarBloque() {
      if (this.bloque != null) {
        this.filtros.bloque_id = this.bloque.id;
      } else {
        this.filtros.bloque_id = null;
      }
    },

    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    seleccionarSitio() {
      if (this.sitio != null) {
        this.filtros.sitio_id = this.sitio.id;
      } else {
        this.filtros.sitio_id = null;
      }
    },

    buscarEmpresas(search) {
      if (search != "") {
        let me = this;
        this.cargando = true;
        var url = "api/admin/empresas/lista";
        axios
          .get(url, {
            params: {
              razon: search,
              linea_negocio_id: [4],
            },
          })
          .then(function(response) {
            me.empresas = response.data;
            me.cargando = false;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    /*
    getRutas() {
      axios.get("/api/cs/rutas/lista").then((response) => {
        this.listasForms.rutas = response.data;
      });
    },

    seleccionarRuta() {
      this.filtros.ruta_id = "";
      if (this.ruta) {
        this.filtros.ruta_id = this.ruta.id;
      }
    },
 */
    limpiar() {
      this.filtros.id = "";
      this.filtros.tipo_servicio = "";
      this.filtros.placa = "";
      this.filtros.persona_type = "";
      this.filtros.nombre = "";
      this.filtros.bloque_id = "";
      this.bloque = null;
      /* this.ruta = null; */
      this.filtros.bloque = null;
      this.filtros.sitio_id = "";
      this.sitio = null;
      this.filtros.sitio = null;
      this.filtros.fecha_ini = "";
      this.filtros.fecha_fin = "";
      this.filtros.estado = "";
      this.getIndex();
    },

    verDetalle(servicio) {
      return this.$router.push({
        name: "/Cs/ServiciosInternoForm",
        params: {
          servicio: servicio.servicio,
          postulacion: servicio,
          detalle: 1,
          presupuesto: 1,
        },
      });
    },

    llenarServices(idPost, isChecked) {
      // Actualiza el estado 'checked' en el objeto correspondiente en serviciosInternos.data
      this.serviciosInternos.data.forEach((serv, index) => {
        if (serv.id === idPost) {
          this.$set(this.serviciosInternos.data[index], "checked", isChecked);
        }
      });

      // Limpia formPost antes de volver a llenarlo
      this.formPost = [];

      // Llena formPost con datos de elementos seleccionados
      this.serviciosInternos.data.forEach((serv) => {
        if (serv.checked && serv.det_solicitud_interna != null) {
          this.formPost.push({
            id: serv.id,
            equipo_type: serv.equipo_type,
            equipo_id: serv.equipo_id,
            persona_type: serv.persona_type,
            persona_id: serv.persona_id,
            persona_2_id: serv.persona_2_id,
            aparejador_id: serv.aparejador_id,
            remolque_id: serv.remolque_id,
            tipo_servicio: serv.det_solicitud_interna.tipo_servicio,
            empresa_id: serv.det_solicitud_interna.empresa_id,
            bloque_id: serv.det_solicitud_interna.solicitud_interna.bloque_id,
            sitio_id: serv.det_solicitud_interna.solicitud_interna.sitio_id,
          });
        }
      });
    },

    async createServices() {
      this.cargando = true;
      await axios({
        method: "POST",
        url: "/api/cs/servicios",
        data: { postulacion: this.formPost },
      })
        .then(async (res) => {
          await this.getIndex();
          const data = res.data;

          let html = "";
          if (data.msg_error) {
            html = `<strong>Crear servicio</strong>`;
            data.msg_error.forEach((er) => {
              html += `<div>${er}</div>`;
            });
          }

          await this.$swal({
            title: `Se actualizarón ${data.cuantos_creados} de los ${data.arrayCountPost} registros seleccionados.`,
            html: html,
            icon: "info",
            showCloseButton: true,
          });
          this.cargando = false;
          this.checkPost = [];
          this.formPost = [];
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    finalizarServicio(servicio) {
      if (servicio.detalle_servicios.length == 0) {
        this.$swal({
          title: "No se puede finalizar este servicio!!",
          text: "El servicio no cuenta todavia con reporte de trabajo",
          icon: "warning",
          confirmButtonText: "OK",
        });

        return false;
      } else if (servicio.presupuestos.length == 0) {
        this.$swal({
          title: "No se puede finalizar este servicio!!",
          text:
            "El servicio no cuenta todavia con asignación de cargos contables",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "OK",
        });

        return false;
      } else if (servicio.totalPresupuesto < 100) {
        this.$swal({
          title: "No se puede finalizar este servicio!!",
          text:
            "El servicio no cuenta el 100 % de la asignación de cargos contables",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "OK",
        });

        return false;
      } else {
        this.$swal({
          title: "Finalizar el servicio",
          text: "Está seguro de finalizar este servicio?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Finalizar!",
        }).then((result) => {
          if (result.value) {
            this.cargando = true;
            axios({
              method: "POST",
              url: "/api/cs/servicios/finalizar",
              data: { detalle_id: servicio.id },
            }).then(async (response) => {
              await this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se Finalizo exitosamente el Servicio...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            });
          }
        });
      }
    },

    async llenarModalFirmas(servicio, postulacion) {
      await this.$refs.CsServicioFirmas.getDataFirmas(servicio, postulacion);
    },

    async redireccionamientoSolicitud() {
      this.$swal({
        title: "¿Está seguro de realizar esta acción?",
        text:
          "Por favor validar nuevamente si desea salir de esta pantalla, ya que en caso de aceptar lo redireccionará a la pantalla principal de las solicitudes internas. Le recomendamos verificar si desea salir realmente de esta pantalla! En caso de que si oprima aceptar, en caso de que no oprima cancelar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar!",
      }).then(async (result) => {
        if (result.value) {
          await this.$store.dispatch("getMenus", 10);
          await this.$store.dispatch("getRoutes", this.$store.getters.getMenus);
          await this.$store.dispatch("setRoutes");
          await this.permisoLineaNegocio(10);
          return this.$router.replace("/CargaSeca/SolicitudesInternas");
        }
      });
    },

    permisoLineaNegocio(linea_negocio) {
      let lineasUser = this.$store.state.user.lineas_negocio;
      return (
        lineasUser.find((linea) => linea.id === linea_negocio) !== undefined
      );
    },
  },

  beforeMount() {
    this.getTipoServicio();
    /* this.getRutas(); */
    this.getBloques();
    this.getSitios();
    this.getFirmantes();
    this.getEstados();
  },

  mounted() {
    this.getIndex();
  },
};
</script>

<style>
.bg-lightwarning {
  background-color: #fff3cd !important;
}

.bg-lightdanger {
  background-color: #f8d7da !important;
}

.bg-lightsuccess {
  background-color: #d4edda !important;
}

.table tr[aria-expanded="true"] {
  background-color: #42dca3 !important;
}
</style>
