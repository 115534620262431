var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#00000082","z-index":"10000"},attrs:{"id":"modal-form-detalles","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"true"}},[_c('div',{staticClass:"modal-dialog modal-xl p-0 pt-1",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Detalle del Servicio")]),_c('button',{ref:"closeModalDetalles",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.$parent.getIndex()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body p-0 pt-1"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(0),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Mapa","data-toggle":"tab","href":"#Mapa"},on:{"click":function($event){return _vm.getDatosMapa()}}},[_vm._v("Mapa")])])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Detalles"}},[_c('div',{staticClass:"card-body pt-0 pb-0"},[_c('CsConsultaDetalleCond',{ref:"CsConsultaDetalleCond"})],1)]),(
                _vm.servicioInterno.bloque != undefined &&
                  _vm.servicioInterno.bloque != null
              )?_c('div',{staticClass:"tab-pane",attrs:{"id":"Mapa"}},[(
                  _vm.servicioInterno.bloque != undefined &&
                    _vm.servicioInterno.bloque != null
                )?_c('CsServicioMapa',{ref:"CsServicioMapa"}):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$parent.getIndex()}}},[_vm._v(" Close ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Detalles","data-toggle":"tab","href":"#Detalles"}},[_vm._v("Detalles")])])
}]

export { render, staticRenderFns }