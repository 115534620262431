<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-detalles"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="background-color: #00000082; z-index: 10000"
      data-backdrop="static"
      data-keyboard="true"
    >
      <div class="modal-dialog modal-xl p-0 pt-1" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle del Servicio</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalDetalles"
              @click="$parent.getIndex()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Detalles"
                  data-toggle="tab"
                  href="#Detalles"
                  >Detalles</a
                >
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Mapa"
                  @click="getDatosMapa()"
                  data-toggle="tab"
                  href="#Mapa"
                  >Mapa</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Detalles">
                <div class="card-body pt-0 pb-0">
                  <CsConsultaDetalleCond ref="CsConsultaDetalleCond" />
                </div>
              </div>
              <div
                class="tab-pane"
                id="Mapa"
                v-if="
                  servicioInterno.bloque != undefined &&
                    servicioInterno.bloque != null
                "
              >
                <CsServicioMapa
                  v-if="
                    servicioInterno.bloque != undefined &&
                      servicioInterno.bloque != null
                  "
                  ref="CsServicioMapa"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="$parent.getIndex()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import CsServicioMapa from "../revision/CsServicioMapa";
import CsConsultaDetalleCond from "./CsConsultaDetalleCond";
export default {
  name: "ConsultaDetalle",

  components: {
    CsServicioMapa,
    CsConsultaDetalleCond,
  },
  data() {
    return {
      servicio_id: null,
      servicioInterno: {},
      det_mapa: [],
      conductor_2: false,
      persona_dos: {
        id: null,
        numero_documento: null,
        nombres: null,
        apellidos: null,
      },
    };
  },

  methods: {
    async getIndex(servicio) {
      this.conductor_2 = false;
      this.$parent.cargando = true;
      this.servicio_id = servicio.id;

      this.servicioInterno = {};
      servicio.fecha_ini = moment(servicio.fecha_ini).format(
        "YYYY-MM-DD HH:mm"
      );
      const inicio = moment(servicio.fecha_ini);
      let fecha_ini1 = moment(inicio).format("YYYY-MM-DD");
      let hora_ini1 = moment(inicio).format("HH:mm");

      // Se filtra el contenido de la fecha hora de fin
      let fecha_fin1 = null;
      let hora_fin1 = null;
      if (servicio.fecha_fin) {
        servicio.fecha_fin = moment(servicio.fecha_fin).format(
          "YYYY-MM-DD HH:mm"
        );
        const fin = moment(servicio.fecha_fin);
        fecha_fin1 = moment(fin).format("YYYY-MM-DD");
        hora_fin1 = moment(fin).format("HH:mm");
      }

      this.servicioInterno = await {
        ...servicio,

        fecha_ini1,
        hora_ini1,
        fecha_fin1,
        hora_fin1,
      };
      this.persona_dos = this.servicioInterno.persona_dos
        ? this.servicioInterno.persona_dos
        : {};
      if (this.persona_dos.id) {
        this.conductor_2 = true;
      }

      if (servicio.id) {
        this.$refs.CsConsultaDetalleCond.getIndex(this.servicioInterno);
      }
      this.$parent.cargando = false;
    },

    getDatosMapa() {
      this.det_mapa = [];
      axios
        .get("/api/cs/revision_servicios_internos/ver_mapa", {
          params: {
            servicio_id: this.servicio_id,
          },
        })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.det_mapa = response.data;
          } else {
            this.$swal({
              icon: "error",
              title: "No se encuentra reportes gps para este servicio",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },
  },
};
</script>
