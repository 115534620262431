<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_export_servicios_internos"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Listado Servicios
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="form-group col-md-6">
                      <label for="">Fecha Inicial</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_ini"
                        :class="
                          $v.filtros.fecha_ini.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_fin"
                        :class="
                          $v.filtros.fecha_fin.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-md-5">N° Servicio</label>
                    <input
                      type="number"
                      id="id"
                      v-model="filtros.id"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-md-5">Placa</label>
                    <input
                      type="text"
                      id="placa"
                      v-model="filtros.placa"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-md-5">Bloque</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="bloque"
                      placeholder="Bloque"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.bloques"
                      :filterable="true"
                      @input="getSelectBloque()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-md-5">Sitio</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="sitio"
                      placeholder="Sitio"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.sitios"
                      :filterable="true"
                      @input="getSelectSitio()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-md-5">Empresa</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="empresa"
                      placeholder="Empresa"
                      label="razon_social"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.empresas"
                      :filterable="true"
                      @search="buscarEmpresas"
                      @input="getSelectEmpresa()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-md-5"> Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="id1"
                              v-model="form.id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="id1"
                              >N° Servicio</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="vh_equi"
                              v-model="form.vh_equi"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="vh_equi"
                              >Vehículo / Equipo</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="placa"
                              v-model="form.placa"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="placa"
                              >Placa</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="persona"
                              v-model="form.persona"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="persona"
                              >Tipo Persona</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre1"
                              v-model="form.nombre"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre1"
                              >Nombre</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="remolque"
                              v-model="form.remolque"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="remolque"
                              >Remolque</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_servicio1"
                              v-model="form.tipo_servicio"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_servicio1"
                              >Tipo Servicio</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_ini1"
                              v-model="form.fecha_ini"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="fecha_ini1"
                              >Fecha Inicial</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_fin1"
                              v-model="form.fecha_fin"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="fecha_fin1"
                              >Fecha Final</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="empresa"
                              v-model="form.empresa"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="empresa"
                              >Empresa</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="bloque"
                              v-model="form.bloque"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="bloque"
                              >Bloque</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="sitio1"
                              v-model="form.sitio"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="sitio1"
                              >Sitio</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="trabajo_realizado"
                              v-model="form.trabajo_realizado"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="trabajo_realizado"
                              >Trabajo Realizado</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="
                  !$v.filtros.$invalid &&
                    $store.getters.can('cs.serviciosInterno.export')
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "CsServicioExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      bloque: {},
      empresa: {},
      sitio: {},
      form: {
        id: true,
        vh_equi: true,
        placa: true,
        persona: true,
        nombre: true,
        remolque: true,
        tipo_servicio: true,
        fecha_ini: true,
        fecha_fin: true,
        empresa: true,
        bloque: true,
        sitio: true,
        trabajo_realizado: true,
        estado: true,
      },
      filtros: {
        id: null,
        placa: null,
        bloque_id: null,
        sitio_id: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
      },
      listasForms: {
        bloques: [],
        sitios: [],
        empresas: [],
        estados: [],
      },
    };
  },

  validations: {
    filtros: {
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
    },
  },

  methods: {
    async getBloques() {
      await axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    async getSitios() {
      await axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/143").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        await axios
          .get(url, {
            params: { linea_negocio_id: [4] },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getSelectBloque() {
      this.filtros.bloque_id = null;
      if (this.bloque) {
        this.filtros.bloque_id = this.bloque.id;
      }
    },

    getSelectSitio() {
      this.filtros.sitio_id = null;
      if (this.sitio) {
        this.filtros.sitio_id = this.sitio.id;
      }
    },

    getSelectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
    },

    validaFechas() {
      if (this.filtros.fecha_ini) {
        const hoy = new Date();
        const fecha_ini = new Date(this.filtros.fecha_ini);
        fecha_ini.setDate(fecha_ini.getDate());

        if (fecha_ini >= hoy) {
          this.filtros.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        var fecha_menor = new Date(this.filtros.fecha_ini);
        var fecha_mayor = new Date(this.filtros.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        fecha_menor = new Date(this.filtros.fecha_ini).getTime();
        fecha_mayor = new Date(this.filtros.fecha_fin).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.filtros.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    limpiarExport() {
      this.filtros = {
        id: null,
        placa: null,
        bloque_id: null,
        sitio_id: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
      };
      this.bloque = [];
      this.sitio = [];
      this.empresa = [];
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/cs/servicios/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
          this.$swal({
            icon: "success",
            title: response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getBloques();
    await this.getSitios();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
